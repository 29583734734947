
#page_about.page.page-about.text-left
  //- pre {{anchors}}
  ul.linkList
    li(v-for="anchor in anchors")
      a(:href="'#' + anchor.href", @click="scrollTo(anchor.href)") {{ anchor.title }}
  .container.mt-5
    .row.py-5
      .col-lg-5
        img.w-100(src="/static/img/cheyuwu2024.jpg")
      .col-lg-7
        h1.mt-2 Che-Yu Wu 吳哲宇
        h5 New Media Artist / Founder of Monoame Design
        .pt-4.content(v-html="htmlContentBio")
  .container.pt-4.content(v-html="htmlContent")
    //- .row
    //-   .col-sm-12
    //- .row.row-info.mt-5
    //-   .col-sm-12.col-info.text-left
      //- img.head.animated.fadeIn(src="/static/img/head2.jpg")
      //- h1 Che-Yu Wu
      //- h5.mt-1 New Media Artist, Interaction Designer, Creative Technologist
      //- pre Senior Product Manager @ Outernets
      //- br
      //- p Devoted to Creative Coding, immersive user experience design,<br> full-stack development, sound synthesis, and tutoring.
      //- ul.mt-4
      //-   li
      //-     b 2020-2021
      //-     span  | Senior Product Manager / Creative Technologist @ Outernets
      //-   li
      //-     b 2018-2020
      //-     span  | NYU IDM Graduate Research Assistant @ New York University
      //-   li
      //-     b 2019
      //-     span  | VR / AR Development Intern @ New Reality Co.
      //-   li
      //-     b 2018
      //-     span  | System Engineer @Research Center for Technology and art, Tsing Hua College
      //-   li
      //-     b 2017
      //-     span  | Teacher @ Hahow, Startup of Online education
      //-   li
      //-     b 2014-2018
      //-     span  | Designer / Full-stack Developer @ Monoame Design Studio
      //-   li
      //-     b 2014
      //-     span  | Cartography and AutoLISP Revelopmer @ Shyang-Horng AutoCAD Tech. Center
      //- //- social-links
