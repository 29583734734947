
.page-music
  .container
    .row.my-5
      .col-12
        h1 Music Compositions 音樂創作
    .row
      |
      |
      |
      .col-12.col-sm-12.col-md-6.col-lg-4.album.p-2(v-for="album in albums")
        a.inner(:href="album.link", target="_blank")
          img.w-100(:src="album.cover")
          h2.my-3 {{ album.title }}
    //- .row(v-for="musicList in data2023")
    //-   .col-12
    //-     h2.mt-5.mb-2 {{ musicList.title }}
    //-   .col-sm-6.col-md-4.p-1.d-flex.justify-center(
    //-     v-for="music in sortDate(musicList.songs)"
    //-   )
    //-     |
    //-     |
    //-     |
    //-     |
    //-     iframe(
    //-       :src="'https://streetvoice.com/music/embed/?id=' + music.id",
    //-       width="330",
    //-       height="100",
    //-       frameborder="0",
    //-       marginheight="0",
    //-       marginwidth="0",
    //-       scrolling="no"
    //-     )
