
.page-art
  .container-fluid
    .row
      .col-12.bg-image.d-block(
        style="background-image: linear-gradient( rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%), url('/static/img/240614 Flower of the Other Shore hor-3.jpg'); background-size: cover; background-position: center 35%; aspect-ratio: 16/5;"
      )
        i.text-center.text-white.position-absolute.bottom-0.left-0.w-100(style="bottom: 10px;left: 0px") The Flower of the Other Shore, 2024
  .container-fluid
    //- pre {{sketches}}
    .row
      .col-12.mt-5
        h1.mb-3.text-center "The end of Science is Art"
    .row.display-flex.justify-content-center.align-items-center
      .col-sm-12
        input.float-right.d-none.d-lg-block(
          v-model="keyword",
          placeholder="Search"
        )


  .container
    .row.mt-4
      a.item.col-xl-4.col-lg-6.col-md-6.wow.fadeIn(
        v-for="(item, itemId) in filteredSketches",

        :href="`https://www.openprocessing.org/sketch/${item.visualID}`",
        target="_blank",
        @mouseenter="hoveringItem = item",
        @mouseleave="hoveringItem = null",
        :key="item.title"
      )
        .item-inner
          //- img(
          //-   :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
          //- )
          img.bg(
            :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
          )
          .container-fluid
            .row.align-items-center
              .col-md-4.col-sm-5
                img(
                  :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
                )
              .col-md-8.col-sm-7
                h5 {{ getTitleInfo(item.title).name }}
                .barcode
                  img(
                    :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
                  )
                pre.text-left.mt-5 Date: {{ getTitleInfo(item.title).date }}
