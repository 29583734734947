// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import $ from 'jquery'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/fontawesome-pro-5.8.1-web/css/all.min.css'
import '@/assets/fontawesome-pro-5.8.1-web/js/all.min.js'
import VueMeta from 'vue-meta'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/lib/codemirror.css'
import P5VueMirror from './components/p5-vue-mirror.vue'
import CodeArea from './components/CodeArea.vue'

Vue.use(VueMeta, {
  // The key name for the rendered meta info
  keyName: 'metaInfo',
  // The attribute name assigned to elements with info, set null to disable
  attribute: 'data-vue-meta',
  // Changed to avoid SSR issues with missing meta tags
  ssrAppId: 'app-ssr',
  refreshOnceOnNavigation: true
})

Vue.use(VueCodemirror, {
  options: {
    mode: "javascript",
    tabSize: 4,
    lineNumbers: true,
    line: true
  },
  events: ['scroll']
})
Vue.component('p5-vue-mirror', P5VueMirror)
Vue.use(ElementUI)
Vue.component("CodeArea", CodeArea)

// Analytics only in production
if (typeof window !== 'undefined' && import.meta.env.PROD && document.domain === "cheyuwu.com") {
  import('vue-analytics').then((VueAnalytics) => {
    Vue.use(VueAnalytics.default, {
      id: 'UA-52977512-26',
      router
    })
  })
}

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    cssbg(url) {
      let use_url = (url && url !== '') ? url : '/img/default.jpg'
      return {
        'background-image': `url("${use_url}")`
      }
    }
  }
})

// Function to init Firebase and load data - only called in browser
function initClientOnlyFeatures() {
  // Initialize Firebase
  if (typeof window !== 'undefined' && window.firebase) {
    const config = {
      apiKey: "AIzaSyCRwI0dpZtehRK1QGeQk9qpOGmtJIbH68g",
      authDomain: "wucheyu-portfolio.firebaseapp.com",
      databaseURL: "https://wucheyu-portfolio.firebaseio.com",
      projectId: "wucheyu-portfolio",
      storageBucket: "",
      messagingSenderId: "531217018941"
    };

    window.firebase.initializeApp(config)
    const db = window.firebase.database()
    const worksRef = db.ref('works')

    worksRef.on('value', (snapshot) => {
      store.commit("setWorks", snapshot.val())
    })
  }

  // Load user data
  console.log("Attempting to fetch sketchs.json...")
  fetch("/static/sketchs.json", {
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache'
    }
  })
    .then(response => {
      console.log("Response status:", response.status);
      console.log("Response headers:", [...response.headers].map(h => `${h[0]}: ${h[1]}`).join(", "));
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log("Successfully loaded sketchs.json data:", Object.keys(data));

      // Transform data structure to ensure components can find the visuals in the expected location
      if (data.user) {
        // If visuals are at the top level, move them into user
        if (data.visuals && !data.user.visuals) {
          data.user.visuals = data.visuals;
        }
        // If sketches are at the top level, move them into user.visuals
        else if (data.sketches && !data.user.visuals) {
          data.user.visuals = data.sketches;
        }

        // Log the result of our transformation
        console.log(`Data transformation complete. User has ${data.user.visuals ? data.user.visuals.length : 0} visuals.`);
      } else {
        console.error("Invalid data format: No user object found");
      }

      store.commit("setUserData", data);
      console.log("Store state after commit:", JSON.stringify(store.state.userData).substring(0, 100) + "...");
    })
    .catch(err => {
      console.error('Error loading user data:', err);
    });

  setTimeout(() => {
    store.commit("setLoading", false)
  }, 100)
}

export function createApp() {
  const app = new Vue({
    router,
    store,
    render: h => h(App),
    // Add this for pre-rendering detection
    mounted() {
      // Trigger the render-event for Puppeteer
      document.dispatchEvent(new Event('render-event'))
    }
  })

  return { app, router, store }
}

// Only mount the app in browser environment
if (typeof window !== 'undefined') {
  const { app } = createApp()

  // Initialize client-only features after mount
  router.onReady(() => {
    app.$mount('#app')
    // Delay the initialization to ensure pre-rendering has completed
    setTimeout(() => {
      initClientOnlyFeatures()
    }, 20)
  })
}
