<template lang="pug">
.page-nft.pb-5
  section.container-fluid.full-header
    .row
      .col-12
        video.w-100(
          src="/static/nft/the_soul_of_flowers/231210 The Soul of flowers video.mp4",
          muted,
          autoplay
        )
  section.container.text-left.section-header
    .row.pt-5
      .col-12
        h1
          a(
            href="https://www.fxhash.xyz/generative/slug/the-soul-of-flowers",
            target="_blank"
          ) The Soul of Flowers
    .row
      a.col-md-12(
        href="https://www.fxhash.xyz/generative/slug/the-soul-of-flowers",
        target="_blank"
      )
        .d-flex.justify-content-start.align-items-center.mb-2
          img(
            src="https://www.fxhash.xyz/images/logo.svg",
            style="height: 50px; width: 50px"
          )
          pre.ml-3.mt-3 FXHASH Project #29643
            br.d-block.d-lg-hidden
            | by Che-Yu Wu
    .row
      .col-lg-12
        p In the vast open fields, I once glimpsed the beauty of blooming flowers, Heard indescribable murmurs that became unforgettable. With longing and pain, I moved slowly forward, crossing the northern seas. In a blink, the origin of this long journey seemed lost.
        p Lost in the dawn before the storm, I discovered that some things in life were quietly changing direction. Perhaps, we are living more transparently, more authentically.
        p After releasing this series of works, I am filled with indescribable complex emotions.
          | Perhaps a fragment of the soul has indeed achieved life and will eternally exist.
          | Eventually blossoming into a field of flowers, existing forever.
        p What's wrong with living willfully?
          | Forget everything.
          | On the island of flowers, it's best to be a happy caterpillar.
    .row.my-4
      .col-12.col-lg-4
        h3 Artist
        ul
          li Project Artist: Che-Yu Wu
          li Launch editions: 200
          li Start Date/Time: Dec 6th, 2023

      .col-12.col-lg-5
        h3 Functionalities
        ul
          li Key S: Save
      .col-12.col-lg-3
        a.d-md-block(
          href="https://www.fxhash.xyz/generative/slug/the-soul-of-flowers",
          target="_blank"
        )
          button.btn Project Link
  .container-fluid.pt-5(v-if="flowersImg")
    .row
      |
      |
      |
      |
      |
      |
      |
      |
      |
      |
      |
      .col-lg-4.col-sm-6.col-xs-12.p-1(
        v-for="url in flowersImg",
        target="_blank"
      )
        img.w-100(:src="url", style="aspect-ratio: 1")
</template>

<script>
import soulFishData from "@/assets/soulfish.json";
export default {
  data() {
    return {
      soulFishData,
      flowersImg: [
        "/static/nft/the_soul_of_flowers/flower demo.png",
        "/static/nft/the_soul_of_flowers/f1.jpeg",
        "/static/nft/the_soul_of_flowers/f2.jpeg",
        "/static/nft/the_soul_of_flowers/f3.jpeg",
        "/static/nft/the_soul_of_flowers/f4.jpeg",
        "/static/nft/the_soul_of_flowers/f5.jpeg",
        "/static/nft/the_soul_of_flowers/f6.jpeg",
        "/static/nft/the_soul_of_flowers/f7.jpeg",
        "/static/nft/the_soul_of_flowers/f8.jpeg",
        "/static/nft/the_soul_of_flowers/f9.jpeg",
        "/static/nft/the_soul_of_flowers/f10.jpeg",
        "/static/nft/the_soul_of_flowers/f11.jpeg",
      ],
    };
  },
  components: {},
  mounted() {
    document.querySelector("video").playbackRate = 1.25;
  },
};
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap')
.page-nft
  background-color: black
  color: white
  margin-top: -15px
  font-family: 'Rokkitt', serif
  font-size: 1.2rem
  overflow: hidden
  width: 100%
  .full-header
    max-height: 600px
    overflow: hidden
    display: flex
    justify-content: center
    align-items: center
    padding: 0

  a
    color: white
  h1 a
    color: white
    text-decoration: none
    &:hover
      color: white
      text-decoration: none
  hr
    margin-top: 30px
    border-bottom: solid 1px white
  .demo-container
    display: flex
    justify-content: center
    align-items: center

    iframe
      border: none
      max-width: 600px
  .btn
    @media only screen and (max-width: 768px)
      width: 100%
      margin-top: 15px
  h1
    font-size: 6rem
    font-family: 'Rokkitt', serif
    @media only screen and (max-width: 768px)
      font-size: 5rem
  h2
    font-size: 3rem
  .section-header
    button
      border: solid 1px white
      background-color: black
      color: white
      font-size: 20px
      &:hover
        background-color: white
        color: black

    pre
      color: white
</style>
