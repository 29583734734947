
.social-links
  a(
    v-for="(link, index) in socialLinks",
    :key="index",
    :href="link.href",
    :target="link.target",
    :title="link.label"
  ) 
    |
    |
    |
    |
    |
    i(:class="link.iconClass")
  pre cheyu.wu@monoame.com
