<template lang="pug">
.page-music
  .container
    .row.my-5
      .col-12
        h1 Music Compositions 音樂創作
    .row
      |
      |
      |
      .col-12.col-sm-12.col-md-6.col-lg-4.album.p-2(v-for="album in albums")
        a.inner(:href="album.link", target="_blank")
          img.w-100(:src="album.cover")
          h2.my-3 {{ album.title }}
    //- .row(v-for="musicList in data2023")
    //-   .col-12
    //-     h2.mt-5.mb-2 {{ musicList.title }}
    //-   .col-sm-6.col-md-4.p-1.d-flex.justify-center(
    //-     v-for="music in sortDate(musicList.songs)"
    //-   )
    //-     |
    //-     |
    //-     |
    //-     |
    //-     iframe(
    //-       :src="'https://streetvoice.com/music/embed/?id=' + music.id",
    //-       width="330",
    //-       height="100",
    //-       frameborder="0",
    //-       marginheight="0",
    //-       marginwidth="0",
    //-       scrolling="no"
    //-     )
</template>

<script>
import data2023 from "@/assets/music_streetvoice.json";
export default {
  data() {
    return {
      data2023,
      albums: [
        {
          title: "2024 Soul Weaved 靈魂織",
          cover:
            "https://akstatic.streetvoice.com/playlist_images/fr/an/frank890417/DSKzaFdg8ntuQinbuxuCzY.jpeg?x-oss-process=image/resize,m_fill,h_380,w_380,limit_0/sharpen,80/interlace,1/quality,q_95/format,jpg",
          link: "https://streetvoice.com/frank890417/playlists/918944/",
        },
        {
          title: "231205 Impromptu / feat. Minnie Wang",
          cover:
            "https://akstatic.streetvoice.com/playlist_images/fr/an/frank890417/5AHHzCHPtD9Q6gNsJhaLr9.jpeg?x-oss-process=image/resize,m_fill,h_380,w_380,limit_0/sharpen,80/interlace,1/quality,q_95/format,jpg",
          link: "https://streetvoice.com/frank890417/playlists/917680/",
        },
        {
          title: "2023 Soul Fermented 靈魂釀",
          cover:
            "https://akstatic.streetvoice.com/playlist_images/fr/an/frank890417/8ekXjfMN2dmkD78WUY3SBo.jpeg?x-oss-process=image/resize,m_fill,h_380,w_380,limit_0/sharpen,80/interlace,1/quality,q_95/format,jpg",
          link: "https://streetvoice.com/frank890417/playlists/913924/",
        },
      ],
    };
  },

  mounted() {
    // this.$refs.yt_video.style.height = window.innerHeight + "px";
    // // this.$refs.yt_video.style.marginTop = "-70px";
    // this.$refs.yt_video.style.zIndex = 0;
    // console.log(this.$refs.yt_video.style.height);
  },
  methods: {
    sortDate(list) {
      return list.sort((a, b) => (a.id < b.id ? 1 : -1));
    },
  },
};
</script>

<style lang="sass">
.page-music
  .container
    max-width: 1500px
  iframe
    background-color: black
  .album
    .inner
      border: solid 1px rgba(255,255,255,0.5)
      border-radius: 40px
      transition: 0.5s
      display: block
      overflow: hidden
    img
      user-select: none

    &:hover .inner
      transform: translate(-5px,-5px)
      box-shadow: 10px 5px 30px white
      a
        text-decoration: none
    h2
      color: white
      font-weight: 400
      height: 5rem
</style>
