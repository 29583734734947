
.page-research
  .container.text-left.mt-5
    .row
      .col-sm-12
        h2 The evolution of information theory in the digital era
          h4.date 2018/12/22
        a(href="/static/The evolution of information theory in the digital era (Final Essay | Media theory D | Che-Yu Wu).pdf" target="_blank") Essay Link
        p From the ancient age of human society, information is essential to human beings. We, humans, use drawing and carving to record messages on stone walls. The paintings might tell our ancestors where and how to get foods, make tools, worship their god, or record what happened that we called history. We live on messages about the ancient wisdom of our ancestors which tell us the sign of harvest and get information about our enemies to fight against with.
        br
        p Since the Information Evolution, information has become the central theme of several science topics since the 1940s. It has been considered an important role of the universe. In Cybernetics1. Wiener stated: "information is information not matter or energy," and in Shannon's Information Theory2, he mentioned that information is a specific combination of different symbols which can be carried either by matter and energy. Information are not a sort of text or a piece of knowledge, it becomes the key basepoint of modern digital telecommunication system and the way we consider human being as a information node in “How We Became Posthuman”3.
        br
        p We consider information as a phenomenon, independent from what it is transmitted with, the sender and the receiver. However, is information an isolated element not related to the other roles in the system? Does information keep its consistency throughout different receivers and mediums? How did the invention of the Internet change the way we handle, transmit and interpret the signals, and how does it perform difference to people in the modern world when we are comprehending with common online knowledge base? What happened when our medium has also improved to a high-tech version, which has the ability to process the signal and append additional information to the original one? The following passage will discuss the evolution of information from the ancient age to the future and the hypothesis of how the information model and application in the future might change.
    .row
      .col-sm-12
        h2 Generative Audio research & experiments
          h4.date 2018/10/9
        br
        .entry-content
          p
            | Before I come to New York, I meet a generative audio artist – Chi Po Hao. I visited him many times for his speech and workshops about generative audio, machine learning in generative music and how to design systems for generative arts and music.
          p
            | This week, I read through the “How Generative Music Works, A Perspective” and I thought it is so delicate! The author well organizes the story of how generative music developed and combine live demos that you can play through your browser. It is an intense but easy to learn and feel tutorial that blows my mind!
          p
            a(href='https://teropa.info/loop/#/title') https://teropa.info/loop/#/title
          p
            | The website introduces how it means to define a sound system that produces music with a lot of interesting examples. Traditionally, we think music as a static thing with fixed scores and instruments, but in generative art, which is developed since the 1900s, there are a lot of tries out that attempt to design a dynamic system, which means a set of things working together as parts of a mechanism or an  interconnecting network, that can produce music.
          figure#attachment_254.wp-caption.alignnone
            img.wp-image-254(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.41.38-1024x535.png', alt='', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.41.38-1024x535.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.41.38-300x157.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.41.38-768x402.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.41.38-1200x627.png 1200w')
            figcaption.wp-caption-text Music for Airtport
          p
            | ( 
            a(href='https://teropa.info/loop/#/airports') https://teropa.info/loop/#/airports
            |  )
          p
            | This is the inspired example by delaying the track, the artist make environmental music for airport not through fixed-length tracks, but grab some notes, define a length on the loop and turn the virtual machine turn, as the wheel brings the solid line toward the play point, the flute-like sound will fade In and 7 of them made harmonic and peaceful work!
          p
            | I learn classical music when I was in elementary school for about 7 years, and those new techniques of creating pieces of music really change my imagination of what music heard like and how they can be, even what can be defined as music.
          p
            img.alignnone.wp-image-255(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/5bf745ca9de501e564bcc66f898fd4b4-1024x791.png', alt='', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/5bf745ca9de501e564bcc66f898fd4b4-1024x791.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/5bf745ca9de501e564bcc66f898fd4b4-300x232.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/5bf745ca9de501e564bcc66f898fd4b4-768x593.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/5bf745ca9de501e564bcc66f898fd4b4-1200x927.png 1200w')
          p
            img.alignnone.wp-image-256(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/9d4a50dce3a5dc271040930e5c50e834-1024x664.png', alt='', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/9d4a50dce3a5dc271040930e5c50e834-1024x664.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/9d4a50dce3a5dc271040930e5c50e834-300x195.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/9d4a50dce3a5dc271040930e5c50e834-768x498.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/9d4a50dce3a5dc271040930e5c50e834-1200x778.png 1200w')
          p
            | Terry Riley’s in C,
            br
            | This “score” is defined by 53 pieces and a long rule explanation. It can be played by a group of fewer than 35 people. Everyone in the band can repeat a numbered measure whatever times he wants to. This is a great example to let me think that, maybe we can create more dynamic music for exhibitions, interactive arts, and museums and if the piece is unique at any moment the audience hears it, it might be a great experience!
          figure#attachment_257.wp-caption.alignnone
            img.wp-image-257(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.44.03-1024x540.png', alt='', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.44.03-1024x540.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.44.03-300x158.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.44.03-768x405.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-21-19.44.03-1200x633.png 1200w')
            figcaption.wp-caption-text Terry Riley, 2014
          p
            a(href='https://teropa.info/loop/#/incplayer') https://teropa.info/loop/#/incplayer
          p
            | The website also mentions two ways of generative arts, one is generative methods and one is generative products, the difference between them is one is defining a method and you will get the same result every time, but the other one is every time you play, it contains user interactive and randomness. There is already some generative synthesizer in the APP market, which allows the audience to draw, tap or tilt there phone to engage in the process of making music!
          p
            | The last impressed example I want to show is  Data Sonification, the author mentioned a website called “Listen to Wikipedia”, it sonify the register action and every edit actions of Wikipedia. We, human, often need visual or audio representation to trigger the subconscious that gives us the quantitative or degree of data through non-logical feelings, and I really think that is a great experience if we can perceive those cold data through a way that is so interesting.
          figure#attachment_258.wp-caption.alignnone(style='width: 562px')
            img.wp-image-258(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-22-01.17.13-300x151.png', alt='', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-22-01.17.13-300x151.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-22-01.17.13-768x386.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-22-01.17.13-1024x514.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/螢幕截圖-2018-10-22-01.17.13-1200x603.png 1200w', sizes='(max-width: 562px) 85vw, 562px')
            figcaption.wp-caption-text Listen to Wikipedia
          p
            a(href='http://listen.hatnote.com/') http://listen.hatnote.com/
          p
            | There are lots of fascinating examples here, and after reading I definitely discover more possibilities of how to break the imagination of what we thought as music. So I try to make some generative music experiments too! The first one is Music String with particles hitting them.
          p
            iframe(src='https://www.openprocessing.org/sketch/611472/embed/?plusEmbedHash=YTczMzc0ZGFjNmViMTE5MWRiNGY0NjJhZjM2NDVmZmQ2NjQ5ZDdlODgxNjYwYjg5ZTUxOTcyODMzYmUzMjA2NmEyYmFjMzRmNWJhOTRkZmJjNTI3M2Q3MWM3NWU4Mjc5MDgyMTQxYzU5YjgzZjk0NTE5YTg0MDA3MzE0YjQ2NDhnTlFkbTlKK3lpVjhMVENIU2Y2bTU5NFhqUmk2OVp5bENsWC9RU1lvS2hrZkxUN05oNkNLMnQ5RkpDU0ltTnJrMjFhUURxSkF6dkF2RFRZWDQvdlB3Zz09&plusEmbedTitle=true', width='100%', height='600')
          p
            | and the second one below is the music matrix, each particle has a certain cycle of triggering, and I add some drum sound through adding ADSR to noise and sine waves, it’s very playful and I hope that I can dig into the field of generative music more in the future!
          p
            iframe(title='Music Matrix', src='//codepen.io/frank890417/embed/preview/PypZeZ/?height=521&theme-id=dark&default-tab=js,result', width='100%', height='600', frameborder='no', allowfullscreen='allowfullscreen')
              | See the Pen <a href="https://codepen.io/frank890417/pen/PypZeZ/">Music Matrix</a> by Majer @Monoame Design (<a href="https://codepen.io/frank890417">@frank890417</a>) on <a href="https://codepen.io">CodePen</a>.
    .row
      .col-sm-12
        h2 Fantasy User Interface
          h4.date 2018/10/2

        .entry-content
          h3 Fall in love with FUI (Fantasy User Interface)
          p
            | Recently, I fall in love with FUI, the Fantasy user interface, which we often see in the sci-fi movie. FUI is the combination of the technology we do not have yet – HUD (heads-up displays) and interfaces for the future system and machines.
          p
            | When we are watching this kind of movies, user interfaces of the machines, which might be the control panels of iron man or AI system,  usually have lines and complicate feeling, with glowing frames and holo image floating in the air and some 3D skeleton model turning around gently. Numbers on those interfaces vary in high speed, flashing and jumping and often are about science, aviation or statistics.
          figure#attachment_209.wp-caption.alignnone(style='width: 572px')
            img.wp-image-209(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen-300x128.jpg', alt='', width='572', height='244', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen-300x128.jpg 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen-768x327.jpg 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen-1024x436.jpg 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen-1200x511.jpg 1200w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Batman_vs_Superman_Cyborg_Reveal_screengraphics_by_Jayse_Hansen.jpg 1280w', sizes='(max-width: 572px) 85vw, 572px')
            figcaption.wp-caption-text Interface design by Jayse Hansen in “Batman v.s. Superman”
          figure#attachment_213.wp-caption.alignnone(style='width: 579px')
            img.wp-image-213(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design4-1-300x169.jpg', alt='', width='579', height='326', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design4-1-300x169.jpg 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design4-1-768x433.jpg 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design4-1.jpg 905w', sizes='(max-width: 579px) 85vw, 579px')
            figcaption.wp-caption-text Bradley G Munkowitz’s FUI Design
          p  
          figure#attachment_214.wp-caption.alignnone(style='width: 582px')
            img.wp-image-214(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design36-300x133.jpg', alt='', width='582', height='258', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design36-300x133.jpg 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design36-768x342.jpg 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/FUI-Design36.jpg 906w', sizes='(max-width: 582px) 85vw, 582px')
            figcaption.wp-caption-text Scene of FUI in Movies
          h3 Psycho-Pass
          p
            | I get lots of interest in FUI after I watch the animation – Psycho-Pass last week. It’s a Japanese animation talking about Shibboleth, which is a judicial system of the society for the future world, can read one’s mind by an ultra-sonic scanner replaced the law system and determine what everyone’s job based on measurement. They use lots of FUI to design what the system might look like in 2100 a.c.
          figure#attachment_210.wp-caption.alignnone(style='width: 566px')
            img.wp-image-210(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-8.51.24-PM-300x169.png', alt='', width='566', height='319', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-8.51.24-PM-300x169.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-8.51.24-PM-768x434.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-8.51.24-PM-1024x578.png 1024w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-8.51.24-PM.png 1197w', sizes='(max-width: 566px) 85vw, 566px')
            figcaption.wp-caption-text Interface Demonstration in the end song of Psycho-Pass
          p
            | After that, I start to search for some introductions of FUI on the internet. There’s a lot of those on Pinterest, and movie posts, and I find that there are famous artists who are noted in the FUI field, which are Ash Thorp, Jayse Hansen, and Bradley G Munkowitz. They design the interface for lots of sci-fi movie, such as 
            em Ender’s Game
            |  and 
            em Total Recall
            | .
          p
            iframe(src='//player.vimeo.com/video/87056142?title=0&byline=0&portrait=0&color=8dc7dc', width='100%', height='320px', allowfullscreen='allowfullscreen')
            br
            em Ender’s Game FUI Reel and Breakdowns by Ash Thorp and Jayse Hansen
          h3 The beautiful and professional part of FUI
          p
            | The designs not only with delicate animations but also elegant graphics that reveal the beauty of structures and data. Also, to design solutions and make brand guidelines of the FUI, the designers even reference whole books, such as plane pilot manuals, to observe what data will be measured, analysis and manipulate in the real world. To be a great designer in FUI, you must have numerous amount of knowledge and imagination of how the world operates, in the field of Biology, Structural design, Computer Science and Chemsitry, and the details are undoubtedly the key point of good visuals.
          p
            iframe(src='//player.vimeo.com/video/64377100?title=0&byline=0&portrait=0&color=8dc7dc', width='100%', height='320px', allowfullscreen='allowfullscreen')
              | <span style="width: 0px;overflow: hidden;line-height: 0" class="mce_SELRES_start">&#65279;</span>
            br
            a(href='https://gmunk.com/OBLIVION-GFX')
              em OBLIVION GFX Montage by Joseph Kosinski
          h3 My Personal experiment to design FUI through p5.js
          p
            | After seeing the references, I pick some element from what I have seen. Using Data simulation, Perlin noise to generate a matrix of data, and then formulate the rules like: When the cell number exceeds 60, it will become red, and if it is over 80, a danger-waring circle will appear to tag in out to notify the users. Then I follow the design pattern of half-transparent lines, using lines to emphasize the structure and make some flashing and glittering numbers to show system status. After 3 hours of work with p5.js, I finally come out with an amazing dynamic website!
          figure#attachment_212.wp-caption.alignnone(style='width: 554px')
            img.wp-image-212(src='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-9.18.33-PM-300x243.png', alt='', width='554', height='449', srcset='https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-9.18.33-PM-300x243.png 300w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-9.18.33-PM-768x621.png 768w, https://s18798.pcdn.co/cheyuideation/wp-content/uploads/sites/12292/2018/10/Screen-Shot-2018-10-01-at-9.18.33-PM.png 788w', sizes='(max-width: 554px) 85vw, 554px')
            figcaption.wp-caption-text
              | FUI experiment by Che-Yu Wu(me) (
              a(href='https://www.openprocessing.org/sketch/598254') link
              | )
          h3 Conclusion
          p
            | I think I try to dig into some Data Visualisation stuff to have more fundamental knowledge of how to turn data into graphical views. It’s not only an enjoyable process to create this work, and its also encouraging that after the input of style and try to simulate the legendary designers in the professional field, I might have the potential to become one. In the future, I will plan to work more and research more about the FUI!
          p  
          h4
            strong References:
          p
            | Movie FUI Collections: 
            a(href='https://www.noteloop.com/kit/fui/movie/') https://www.noteloop.com/kit/fui/movie/
          p
            | FUI Pinterest Board: 
            a(href='https://www.pinterest.com/kghsu/%E8%99%9B%E6%A7%8B%E4%BB%8B%E9%9D%A2%E8%A8%AD%E8%A8%88fui-design/?lp=true')
              | https://www.pinterest.com/kghsu/%E8%99%9B%E6%A7%8B%E4%BB%8B%E9%9D%A2%E8%A8%AD%E8%A8%88fui-design/?lp=true
          p
            | OBLIVION GFX: 
            a(href='https://gmunk.com/OBLIVION-GFX') https://gmunk.com/OBLIVION-GFX
          p
            | A Chinese Design Magzine post introduction of  FUI: 
            a(href='https://www.mydesy.com/fui-design') https://www.mydesy.com/fui-design


