
.page.page-works
  .container-fluid

    //.row
      .col-sm-12
        .catas
          .cata(@click="currentTag=''", :class="{active: !currentTag}") All
          .cata(v-for="tag in defaut_hashtags",
                @click="currentTag = tag",
               :class="{active: currentTag==tag}") {{tag}}

    .row.row-work.mb-3.text-left
      router-link.col-xs-12.col-sm-12.col-md-6.col-lg-6.col-xl-4.col-work.animated.fadeIn.p-0(
          v-for="(work,wid) in sortedWorks",
          :to="`/project/${getProjectName(work.title)}`",
          :key="work.uid",
          v-if="!currentTag || work.cata.indexOf(currentTag)!=-1",
        )

        .work(
          :style="cssbg(work.cover)")
          video(v-if="work.video" :src="work.video" muted autoplay loop)
          .fixed
            h3.mt-4 {{work.title}}
            h6.mt-3 {{work.client}}

        //.tags
          .tag(v-for="tag in work.cata") {{tag}}

