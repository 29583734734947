<template lang="pug">
.page-index
  .container
    .row.mt-5
      .col-12
        .row.align-items-center
          .col-md-5.mb-4.mb-md-0
            img.w-100.rounded(src="/static/img/CheYu-0401-crop.jpg" alt="Che-Yu Wu")
          .col-md-7
            .project-description
              h2.text-left Che-Yu Wu 吳哲宇
              p.quote.text-left "Crafting eternal life and emotions in the digital realm."
              //p.links
                a(href="https://cheyuwu.com/" target="_blank") Website
                span.mx-2 |
                a(href="https://portaly.cc/cheyuwu_en" target="_blank") Portaly
            .project-description.mt-4.text-left
              p Che-Yu Wu, born in 1995 in Taiwan, is a contemporary new media artist, engineer, and music creator. His works are driven by algorithms, crafting eternal life and emotion within the digital realm. Wu holds a degree in Electrical Engineering from National Yang Ming Chiao Tung University and pursued graduate studies at New York University's Integrated Digital Media program, specializing in computational art and interactive technologies.
              p Situated at the intersection of engineering and art, Wu's creative practice is deeply rooted in his exploration of nature, physics, modern art, and mathematics. By leveraging cutting-edge algorithms and interactive systems, he forges a distinctive digital language that imbues his artworks with vitality. Visually captivating, his pieces also construct emotional and soulful bridges between the virtual and the real—inviting audiences to engage with the poetic dimensions of digital life and the essence of existence.
    .row.mt-4
      .col-12
        //h2.mt-5 Notable Works & Exhibitions
        //.project-description.text-left
          p His notable works include Seaham, Electriz, CryptoPochi, SoulFish, The Soul of Flowers, and Urban Contour. Among these, The Soul of Flowers was presented at Art Basel, and SoulFish was showcased at the Venice Biennale, both earning critical acclaim. In 2022, Wu held a solo exhibition titled The Chaos Lab at Ambi Space One in Taipei 101, followed by his 2023 solo exhibition The Great Equation: A Journey of Digital Life Evolution, which delved into the digital genesis from the inorganic to the emergence of life.
        h2.mt-5 Artistic Method and Creative Process
        .project-description.text-left
          p Wu's artistic philosophy is profoundly influenced by Generative Art, an approach that employs automated systems to produce works defined by the limitless potential of algorithms and code. He writes programs that generate dynamic visuals, 3D artworks, and music—his creative process resembles cultivating life forms in a digital petri dish. These algorithmic entities evolve and mutate autonomously, ultimately becoming unique works of art.
          p For Wu, code and systems are not merely tools; they are digital poems—living artworks that transform colors and forms with pulsating vitality. Some of his pieces incorporate immersive installations and motion-tracking technologies, deepening viewer interaction and constructing dynamic digital ecosystems.
          p Since his university years, Wu has been exploring the convergence of art and engineering. His studies in "Creative Coding" at NYU solidified his unique perspective of using programming languages as artistic media. Over the years, he has created hundreds of generative art pieces, each embodying a harmonious blend of engineering precision and artistic imagination. His works not only mesmerize with their visual allure but also build profound emotional connections, offering a poetic space where the digital and the organic intertwine.
          p Wu firmly believes that the ultimate frontier of science is art. Through digital technology, he manifests this conviction by composing digital poems filled with life and emotion—each work a living piece of art.
    .row.mb-5.mt-5
      .col-12
        h2 2024 - 瓶中的永恆花園：不穩定系統中的無限演算與數位生命
        p The Eternal Garden in a Bottle: Infinite Computation and Digital Life in an Unstable System
        iframe.index_yt.w-100(
          ref="yt_video",
          src="https://www.youtube.com/embed/bTx4lC3oA_Y?autoplay=1&origin=https://cheyuwu.com&control=0&info=0&loop=1",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )
        .project-description
          p 《瓶中的永恆花園：不穩定系統中的無限演算與數位生命》是我於2024年11至12月在巴黎CENTQUATRE駐村期間創作的作品，靈感源自瓦德安箱（Wardian Case）——一個自我維持的生態系統。這件作品探索人與機器之間的動態循環，透過數位技術構築一個受混沌理論影響、不斷演化的數位生態系，並由觀眾與音樂家的互動驅動其變化，進而探討不穩定系統中的平衡與數位生命的可能性，最後以音樂和視覺形式呈現。
          p "The Eternal Garden in a Bottle: Infinite Computation and Digital Life in an Unstable System" is a work I created during my residency at CENTQUATRE in Paris from November to December 2024, inspired by the Wardian Case—a self-sustaining ecosystem. This project explores the dynamic interplay between humans and machines, constructing a digital ecosystem influenced by chaos theory and constantly evolving through interactions with audiences and musicians. It examines balance within unstable systems and the potential of digital life, ultimately expressed through music and visuals.
    .row
      .col-12
        h2 2023 - 萬物公式 The Great Equation
        iframe.index_yt.w-100(
          ref="yt_video",
          src="https://www.youtube.com/embed/9CAwja6UBJs?autoplay=1&origin=https://cheyuwu.com&control=0&info=0&loop=1",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )
        .project-description
          p 「萬物公式：數位生命演化之旅」是一場探索數位生命本質的藝術展演。展覽分為三個篇章：無機、有機到生命篇，呈現了從最基本的粒子到複雜生命系統的演化過程。在無機篇中，觀眾可以看到粒子在虛空中的誕生與消滅；在有機篇裡，這些粒子開始突變成數位生命，長出枝芽；最後在生命篇中，這些生命開始建構自己的世界，從島嶼印象到靈魂之海，直至最後的光之信仰。整個展覽空間本身就是一個有機的系統，觀眾的存在與移動都會成為作品演算的一部分，使每次的展演都是獨一無二的體驗。
          p "The Great Equation: A Digital Evolution of Life" is an artistic exploration into the essence of digital life. The exhibition unfolds in three chapters: Inorganic, Organic, and Life, showcasing the evolution from basic particles to complex living systems. In the Inorganic chapter, viewers witness particles emerging and dissipating in the void; in the Organic chapter, these particles mutate into digital life forms, sprouting branches; and in the Life chapter, these beings construct their own worlds, from Island Impressions to the Sea of Souls, culminating in the Faith of Light. The exhibition space itself is an organic system where visitors' presence and movement become part of the algorithmic performance, making each viewing experience unique.
        h3.mt-4 展覽章節 Exhibition Chapters
        .project-description
          p 展覽分為三個篇章，從無機到有機，最後到生命篇。在無機篇中，粒子在虛空中誕生與消滅，畫出蜿蜒軌跡；在有機篇中，這些粒子突變成數位生命，長出枝芽，形成遊走的村落；在生命篇中，作品展現了從島嶼印象到靈魂之海的演化過程，最終在光之信仰中，探索生命與藝術的本質。每個章節都融合了視覺與音效，創造出獨特的沉浸式體驗。
          p The exhibition is structured in three chapters, progressing from Inorganic to Organic, and finally to Life. In the Inorganic chapter, particles emerge and vanish in the void, tracing meandering paths. The Organic chapter sees these particles mutating into digital life forms, sprouting branches and forming wandering villages. The Life chapter showcases evolution from Island Impressions to the Sea of Souls, culminating in the Faith of Light, exploring the essence of life and art. Each chapter integrates visuals and sound to create a unique immersive experience.
        iframe.index_yt.w-100(
          ref="yt_video",
          src="https://www.youtube.com/embed/czsaFHdBfJQ?autoplay=1&origin=https://cheyuwu.com&control=0&info=0&loop=1",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )
    .row
      .col-12
        h2 2022 - 混沌實驗室 Chaos Laboratory
        iframe.index_yt.w-100(
          ref="yt_video",
          src="https://www.youtube.com/embed/B3mqk5w8FVo?autoplay=1&origin=https://cheyuwu.com&control=0&info=0&loop=1",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )
        .project-description
          p 「混沌實驗室」是台灣NFT藝術先驅之一，新銳生成式藝術家吳哲宇首次實體個展，集結2020-2022年間的NFT藝術創作，精選50件生成式演算作品。創作靈感取材自然、物理、現代藝術、數學韻律與音樂，結合古典與現代電音譜曲，現場演奏與組合樂段即時影響程式視覺。展覽將線上互動式的NFT虛擬作品轉譯為沉浸式體驗展演，於全台首創五面環影沉浸式空間「双融域 AMBI SPACE ONE」呈現，帶領觀眾走進神秘的NFT虛擬世界，探索互動演算與程式藝術創作的無限可能性。
          p "Chaos Laboratory" marks the first physical solo exhibition by Che-Yu Wu, one of Taiwan's NFT art pioneers. The exhibition showcases 50 selected generative algorithmic works from his NFT art creations between 2020-2022. Drawing inspiration from nature, physics, modern art, mathematical rhythms, and music, the works combine classical and modern electronic compositions where live performances and musical segments directly influence the programmed visuals. The exhibition transforms online interactive NFT virtual works into an immersive experience, presented in Taiwan's first five-sided immersive space "AMBI SPACE ONE," leading audiences into the mysterious world of NFTs while exploring the infinite possibilities of interactive algorithms and programmatic art creation.
        h3.mt-4 關於生成藝術與NFT About Generative Art and NFT
        .project-description
          p 生成藝術（Generative Art）透過寫程式碼，讓演算法自己生成圖像或影像等作品。區塊鏈快速發展後，生成藝術創作者常將作品上鏈製成NFT進行販售，也正因近年NFT熱潮使得大量生成藝術作品被發掘。NFT（Non-fungible token）又稱為非同質化代幣，非同質的意思是指獨一無二，代幣則是指在區塊鍊上的一串代碼。NFT屬於數位加密貨幣的一種，建立在區塊鏈上，可詳細記載難以被竄改的所有權資訊，每個代幣可以代表一個獨特的數位資料，像是圖片、音檔、影片、遊戲專案，甚至是一則社群貼文，任何數位形式的創意作品，讓昔日能輕易複製另存的數位檔案，如今能透過NFT具備所有權證明。
          p Generative Art creates artworks through programming, allowing algorithms to generate images or videos autonomously. With the rapid development of blockchain technology, generative artists often mint their works as NFTs for sale, leading to the discovery of numerous generative artworks during the recent NFT boom. NFT (Non-fungible token) refers to unique tokens on the blockchain. As a form of digital cryptocurrency built on blockchain technology, NFTs provide tamper-resistant ownership information. Each token can represent unique digital data, such as images, audio files, videos, game projects, or even social media posts. Through NFTs, easily replicable digital files now have verifiable proof of ownership.

</template>

<script>
export default {
  mounted() {
    // this.$refs.yt_video.style.height = window.innerHeight + "px";
    // // this.$refs.yt_video.style.marginTop = "-70px";
    // this.$refs.yt_video.style.zIndex = 0;
    console.log(this.$refs.yt_video.style.height);
  },
};
</script>

<style lang="sass">
.page-index
  h2
    margin: 30px 0px
  h3
    margin: 40px 0 20px
    font-size: 1.5rem
  .index_yt
    aspect-ratio: 16/9
    border: none
  .project-description
    margin: 30px 0
    p
      margin-bottom: 20px
      line-height: 1.6
      font-size: 16px
      &.quote
        font-style: italic
        font-size: 1.5rem
        color: #aaa
        margin: 20px 0
      &.links
        margin-top: 20px
        a
          color: #333
          text-decoration: none
          &:hover
            text-decoration: underline
        span
          color: #999
  img
    box-shadow: 0 4px 8px rgba(0,0,0,0.1)
    transition: transform 0.3s ease
    &:hover
      transform: scale(1.02)
  .exhibition-info
    background: rgba(0,0,0,0.03)
    padding: 20px
    border-radius: 8px
    margin: 20px 0
    p
      margin-bottom: 15px
      &:last-child
        margin-bottom: 0
    strong
      font-weight: 600
      margin-right: 10px
    ul
      list-style-type: none
      padding-left: 20px
      margin-bottom: 20px
      li
        margin-bottom: 8px
        &:last-child
          margin-bottom: 0
    .hashtags
      margin-top: 30px
      span
        display: inline-block
        background: rgba(0,0,0,0.05)
        padding: 5px 12px
        border-radius: 20px
        margin: 0 8px 8px 0
        font-size: 14px
</style>
