
.page-project
  .container-fluid
    .row
      .col-sm-12
        .cover(:style="cssbg(work.cover)")
          router-link.btn-back(to="/")
            i.fa.fa-angle-left.mr-3
            span Back to Works
    .container.content-area
      .row(v-if="work")
        .col-sm-12
          h1 {{ work.title }}

      .row
        .col-sm-6.col-content
          ul.text-left
            li
              label Client
              span {{ work.client }}
            li
              label Date
              span {{ work.date }}
            li
              label Category
              span {{ work.type }}
            li
              label Responsibilities
              span {{ work.work }}
            li(v-if="work.link")
              label Link
              a(:href="work.link", target="_blank") {{ work.link }}
        .col-sm-6.col-content
          ul.text-left
            li
              label Honors
              p(v-html="processHTML(work.honor)")
      .row
        .col-sm-12.col-content
          p.text-left(v-html="processHTML(work.content)")
        //- h3 Project Information
    .row.row-nav
      router-link.col-sm-6.col-nav(
        :to="`/project/${projnav.pre.id}`",
        v-if="projnav.pre.id",
        :style="cssbg(projnav.pre.work.cover)"
      )
        h3 {{ projnav.pre.work.title }}

      router-link.col-sm-6.col-nav(
        :to="`/project/${projnav.nxt.id}`",
        v-if="projnav.nxt.id",
        :style="cssbg(projnav.nxt.work.cover)"
      )
        h3 {{ projnav.nxt.work.title }}
