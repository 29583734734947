
.page-edit.page-project-edit
  .container-fluid.pt-5

    .row

      .col-sm-3.col-list

        //ul.breadcrumb
          li.breadcrumb-item
            router-link(to="/", target="_blank") 管理
          li.breadcrumb-item 編輯專案
        div.text-left.pl-3
          label.mr-2 顯示/隱藏
          el-switch(v-model="showHiddenWorks")
        ul.list-group.text-left
          li.list-group-item(v-for="(w,wid) in sortedWorks",
                             @click="nowId=w.uid", :class="{active:nowId==w.uid, show: w.show}",
                             v-if="w.show || showHiddenWorks ")
            .row
              .col-11
                span {{wid+1}}.
                span {{w.title}}
                //input(v-model="w.order")
              .col-1
                span(@click="removeItem(wid)")
                  i.fas.fa-trash
        el-button.pt-4.pb-4(@click="addItem" type="primary" style="width: 100%") + Add Item
              //.col-2
                el-input.input-order-number(type="number" v-model="w.order")
      .col-sm-3

      .col-sm-9(v-if="work ", :key="nowId")
        .container-fluid.text-left
          .row
            .col-sm-12

              h2
                .row
                  .col-9
                    el-input.input-title(v-model="work.title")
                  .col-3
                    router-link.btn.btn-secondary.float-right(:to="'/project/'+toComp(work.title)", target="_blank") Open Project
                    button.btn.btn-primary.float-right.mr-2(@click="saveAll") Save
              hr

          .row.pt-3
            .col-sm-3
              el-form(label-width="60px")
                //el-form-item(label="title")
                  el-input(v-model="work.title")
                //- el-form-item(label="順序")
                  el-input(v-model="work.order")
                el-form-item(label="連結")
                  el-input(v-model="work.link")
                el-form-item(label="影片")
                  el-input(v-model="work.video")
                el-form-item(label="順序")
                  el-input(v-model="work.order")
                el-form-item(label="顯示")
                  el-switch(v-model="work.show")
                //el-form-item(label="顏色")
                  el-color-picker(v-model="work.color")
                el-form-item(label="客戶")
                  el-input(v-model="work.client")
                el-form-item(label="類別")
                  el-input(v-model="work.type")
                el-form-item(label="Tag")
                  el-select(v-model="work.cata"
                            multiple
                            filterable
                            allow-create
                            default-first-option
                            placeholder="請選擇Hashtag或建立")
                    el-option(
                      v-for="item in defaut_hashtags"
                      :key="item"
                      :label="item"
                      :value="item")
                el-form-item(label="職責")
                  el-input(v-model="work.work")
                el-form-item(label="封面")
                  el-input(v-model="work.cover")
                  .row
                    .col-sm-3
                      img(v-if="work.cover" :src="work.cover" class="avatar", width="100px")
                    .col-sm-9
                      el-upload(
                        action="string"
                        class="avatar-uploader"
                        :http-request="uploadImage"
                        :show-file-list="false"
                      )
                        i(class="el-icon-plus avatar-uploader-icon")
                el-form-item(label="日期")
                  el-input(v-model="work.date")
                el-form-item(label="榮譽")
                  el-input(v-model="work.honor", type="textarea")

            .col-sm-9
              el-form
                el-form-item(label="")
                  VueEditor.ve(:id ="'content'", v-model="work.content" ,
                    :useCustomImageHandler="true",
                    @imageAdded="handleImageAdded" ,
                    style="height: 700px;margin-bottom: 50px")
                  //- el-input(v-model="work.content", type="textarea")

