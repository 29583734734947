<template>
  <div class="p5-vue-mirror">
    <div class="preview" ref="preview"></div>
    <codemirror v-model="code" :options="cmOptions"></codemirror>
  </div>
</template>

<script>
import p5 from 'p5'

export default {
  name: 'p5-vue-mirror',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      code: this.value,
      p5instance: null,
      cmOptions: {
        mode: 'javascript',
        theme: 'default',
        lineNumbers: true,
        line: true
      }
    }
  },
  watch: {
    code(newVal) {
      this.$emit('input', newVal)
      this.updateSketch()
    },
    value(newVal) {
      this.code = newVal
    }
  },
  methods: {
    updateSketch() {
      if (this.p5instance) {
        this.p5instance.remove()
      }
      try {
        const sketch = new Function('p', this.code)
        this.p5instance = new p5(sketch, this.$refs.preview)
      } catch (e) {
        console.error('Error in p5 sketch:', e)
      }
    }
  },
  mounted() {
    this.updateSketch()
  },
  beforeDestroy() {
    if (this.p5instance) {
      this.p5instance.remove()
    }
  }
}
</script>

<style>
.p5-vue-mirror {
  display: flex;
  flex-direction: column;
}
.preview {
  width: 100%;
  min-height: 300px;
  background: #f0f0f0;
}
</style>
