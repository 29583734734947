
.page.page-experiment.pt-3
  .container
    .row
      .col-sm-2.col-lg-3.nav-exprs
        .expr_nav.text-left(v-for='(expr,exprid) in experiments',
          :class="{active: exprid==currentIndex}"
          @click="currentIndex = exprid").mb-2
          .cover_sm(:style="{'background-image': 'url('+expr.cover+')'}")
            span {{expr.title}}
          //- pre {{expr.date}}
      .col-sm-10.col-lg-9
        iframe(:src="experiment.src", style="height: 80vh" )

