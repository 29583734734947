
<div class="codeArea">
  <iframe
    :key="key"
    height="400px"
    :srcdoc="`<html><head><script src='https://cdnjs.cloudflare.com/ajax/libs/p5.js/0.10.2/p5.js'><\/script><script src='https://cdnjs.cloudflare.com/ajax/libs/p5.js/0.10.2/addons/p5.sound.min.js'><\/script></head>${enableMotion?motionButtonStyle:''}<body>${enableMotion?motionButtonCode:''}${scriptHtml}<script>${embedCodes}; function windowResized() {resizeCanvas(windowWidth, windowHeight);}<\/script><style>html,body{margin: 0;overflow: hidden;}</style></body>`"
  />
  <div v-if="!hidecode">
    <button class="btn btn-light btn-rerun" @click="restartCode">
      <i class="fas fa-redo-alt"></i>
    </button>
    <codemirror v-model="value" :hidecode="true" />
  </div>
</div>
