import Vue from 'vue'
import Router from 'vue-router'
import pageIndex from '@/components/pages/pageIndex.vue'
import pageWorks from '@/components/pages/pageWorks.vue'
import pageAbout from '@/components/pages/pageAbout.vue'
import pageCV from '@/components/pages/pageCV.vue'
import pageExperiment from '@/components/pages/pageExperiment.vue'
import pageResearch from '@/components/pages/pageResearch.vue'
import pageProject from '@/components/pages/pageProject.vue'
import pageProjectEdit from '@/components/pages/pageProjectEdit.vue'
import pageThesis from '@/components/pages/pageThesis.vue'
import pageArts from '@/components/pages/pageArts.vue'
import pageMusic from '@/components/pages/pageMusic.vue'
import pageNft from '@/components/pages/pageNft.vue'
import pageExhibition2023 from '@/components/pages/pageExhibition2023.vue'
import pageLexus2023 from '@/components/pages/pageLexus2023.vue'
import pageSoulfishYilan2023 from '@/components/pages/pageSoulfishYilan2023.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [{
      path: '/',
      name: 'index',
      component: pageIndex,
      meta: {
        title: 'Home',
        description: 'Cheyu Wu - Portfolio of Generative Algorithmic Artist and Creative Technologist'
      }
    }, {
      path: '/about',
      name: 'about',
      component: pageIndex,
      meta: {
        title: 'About',
        description: 'About Cheyu Wu - Generative Algorithmic Artist and Creative Technologist'
      }
    }, {
      path: '/cv',
      name: 'cv',
      component: pageCV,
      meta: {
        title: 'CV',
        description: 'Curriculum Vitae of Cheyu Wu - Education, Skills, Experience'
      }
    }, {
      path: '/nft',
      name: 'nft',
      component: pageNft,
      meta: {
        title: 'NFT Projects',
        description: 'NFT Projects by Cheyu Wu - Digital Art Collection'
      }
    }, {
      path: '/nft/:name',
      name: 'nft',
      component: pageNft,
      meta: {
        title: 'NFT Details',
        description: 'NFT Project Details by Cheyu Wu'
      }
    },
    {
      path: '/research',
      name: 'research',
      component: pageResearch,
      meta: {
        title: 'Research',
        description: 'Research Work by Cheyu Wu - Academic and Creative Research'
      }
    },
    {
      path: '/experiment',
      name: 'experiment',
      component: pageExperiment,
      meta: {
        title: 'Experiments',
        description: 'Creative Experiments by Cheyu Wu - Digital Art and Technology'
      }
    }, {
      path: '/work',
      name: 'work',
      component: pageWorks,
      meta: {
        title: 'Work',
        description: 'Professional Work by Cheyu Wu - Creative Projects and Collaborations'
      }
    }, {
      path: '/arts',
      name: 'arts',
      component: pageArts,
      meta: {
        title: 'Arts',
        description: 'Art Projects by Cheyu Wu - Creative and Generative Art'
      }
    }, {
      path: '/music',
      name: 'music',
      component: pageMusic,
      meta: {
        title: 'Music',
        description: 'Music Projects by Cheyu Wu - Audio and Sound Design'
      }
    }, {
      path: '/manage',
      name: 'project_edit',
      component: pageProjectEdit,
      meta: {
        title: 'Manage Projects',
        description: 'Project Management'
      }
    }, {
      path: '/thesis',
      name: 'thesis',
      component: pageThesis,
      meta: {
        title: 'Thesis',
        description: 'Academic Thesis by Cheyu Wu - Research and Findings'
      }
    }, {
      path: '/project/:id',
      name: 'project',
      component: pageProject,
      meta: {
        title: 'Project Details',
        description: 'Detailed Project Information by Cheyu Wu'
      }
    }, {
      path: '/exhibition/2023',
      name: 'exhibition2023',
      component: pageExhibition2023,
      meta: {
        title: 'Exhibition 2023',
        description: 'Cheyu Wu Exhibition 2023 - Featured Art and Projects'
      }
    }, {
      path: '/lexus2023',
      name: 'lexus2023',
      component: pageLexus2023,
      meta: {
        title: 'Lexus 2023',
        description: 'Lexus 2023 Project by Cheyu Wu - Creative Collaboration'
      }
    }, {
      path: '/campaign/soulfish_yilan_2023',
      name: 'soulfish_yilan_2023',
      component: pageSoulfishYilan2023,
      meta: {
        title: 'Soulfish Yilan 2023',
        description: 'Soulfish Yilan 2023 Campaign by Cheyu Wu'
      }
    }

  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          resolve({
            x: 0,
            y: 0
          })
        }
      }, 500)
    })

  }
})
