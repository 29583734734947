
#app
  .container-fluid
    .row
      .col-sm-12
        nav(
          v-if="!$route.path.includes('manage') && !$route.path.includes('/project/')"
        )
          //- router-link(to="/" :class="{active: $route.path=='/'}") Index
          router-link.main-logo(to="/")
            h2.d-none.d-md-block
              img(src="/img/cheyuwu/CHEYUWU__Primary Logo_White.png")
            h2.d-md-none
              img(src="/img/cheyuwu/CHEYUWU__WU Symbol_White.png")
          .subroute(:class="{ white: $route.path == '/cv' }")
            router-link(
              to="/about",
              :class="{ active: $route.path == '/about' }"
            ) About
            router-link(to="/cv", :class="{ active: $route.path == '/cv' }") CV
            //router-link(to="/experiment" :class="{active: $route.path=='/experiment'}") Experiments
            router-link(
              to="/arts",
              :class="{ active: $route.path == '/arts' }"
            ) Arts
            //- router-link(
            //-   to="/exhibition/2023",
            //-   :class="{ active: $route.path == '/exhibition/2023' }"
            //- ) Exhibition
            router-link(
              to="/music",
              :class="{ active: $route.path == '/music' }"
            ) Music
            router-link(to="/nft", :class="{ active: $route.path == '/nft' }") NFT
            //- router-link.d-none.d-md-inline-block(
            //-   to="/thesis",
            //-   :class="{ active: $route.path == '/thesis' }"
            //- ) Thesis
            router-link(
              to="/work",
              :class="{ active: $route.path == '/work' }"
            ) Work
            //- router-link(to="/research" :class="{active: $route.path=='/research'}") Research
            //- a(href="/static/Che-Yu Wu Resume.pdf" target="blank" :class="{active: $route.path=='/resume'}") Resume
            a(href="https://www.instagram.com/cheyuwu345/", target="_blank")
              i.fab.fa-instagram
  transition(name="page", mode="out-in")
    pageLoading(v-if="loading")
  transition(name="page", mode="out-in")
    router-view(:key="$route.path")

  .row.copyright.mt-5(v-if="$route.path != '/thesis'")
    .col-sm-12.mt-2.pt-1.pb-5
      label Copyright© Che-Yu Wu, 2024
      .text-center.mt-2
        social-links
