<template lang="pug">
.page-art
  .container-fluid
    .row
      .col-12.bg-image.d-block(
        style="background-image: linear-gradient( rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%), url('/static/img/240614 Flower of the Other Shore hor-3.jpg'); background-size: cover; background-position: center 35%; aspect-ratio: 16/5;"
      )
        i.text-center.text-white.position-absolute.bottom-0.left-0.w-100(style="bottom: 10px;left: 0px") The Flower of the Other Shore, 2024
  .container-fluid
    //- pre {{sketches}}
    .row
      .col-12.mt-5
        h1.mb-3.text-center "The end of Science is Art"
    .row.display-flex.justify-content-center.align-items-center
      .col-sm-12
        input.float-right.d-none.d-lg-block(
          v-model="keyword",
          placeholder="Search"
        )


  .container
    .row.mt-4
      a.item.col-xl-4.col-lg-6.col-md-6.wow.fadeIn(
        v-for="(item, itemId) in filteredSketches",

        :href="`https://www.openprocessing.org/sketch/${item.visualID}`",
        target="_blank",
        @mouseenter="hoveringItem = item",
        @mouseleave="hoveringItem = null",
        :key="item.title"
      )
        .item-inner
          //- img(
          //-   :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
          //- )
          img.bg(
            :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
          )
          .container-fluid
            .row.align-items-center
              .col-md-4.col-sm-5
                img(
                  :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
                )
              .col-md-8.col-sm-7
                h5 {{ getTitleInfo(item.title).name }}
                .barcode
                  img(
                    :src="hoveringItem === item && getGifUrl(item) ? getGifUrl(item) : getThumbnail(item)"
                  )
                pre.text-left.mt-5 Date: {{ getTitleInfo(item.title).date }}
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // userData: {}
      hoveringItem: null,
      keyword: "",
    };
  },
  mounted() {
    if (typeof window !== 'undefined' && window.WOW) {
      new window.WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        scrollContainer: null, // optional scroll container selector, otherwise use window
      }).init();
    }

    // Add debugging for userData structure
    console.log('PageArts mounted, userData:', this.userData);
    if (this.userData.user) {
      console.log('User visuals count:', this.userData.user.visuals ? this.userData.user.visuals.length : 0);
    }
  },

  computed: {
    ...mapState(["userData"]),
    sketches() {
      if (this.userData.user) {
        let results = (this.userData.user.visuals || []).filter(
          // (p) => p.pinnedOn
          //filter out name start with NNNNNN(6 random digits) then any thing afterward
          p=>p.title.match(/^[0-9]{6}/) && p.visualID
        );
        results.sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1));
        return results;
      }
      return [];
    },
    filteredSketches() {
      if (!this.keyword) {
        return this.sketches;
      } else {
        return this.sketches.filter(
          (sk) =>
            sk.title.toLowerCase().indexOf(this.keyword.toLowerCase()) != -1
        );
      }
    },
  },
  methods: {
    getCacheUrl(url, name) {
      return `https://cheyuwu.com/static/img/cache.php?url=${url}&imgname=${name}`;
    },
    getGifUrl(item) {
      return ((item.description || "").split("[GIF ")[1] || "").split("]")[0];
    },
    getThumbnail(item) {
      return `https://kyoko.openprocessing.org/thumbnails/visualThumbnail${item.visualID}@2x.jpg?hash=${item.createdOn}`;
    },
    getTitleInfo(title) {
      title = title || " ";
      return {
        date: title.split(" ")[0],
        name: title.slice(title.indexOf(" ")),
      };
    },
  },
  created() {},
};
</script>
<style lang="sass">
.page-art
  background-color: #000
  color: white
  padding-top: 0px
  min-height: 100vh
  h1
    font-family: serif
  .barcode
    // transform: scaleY(100)
    overflow: hidden
    height: 20px
    width: 100%
    @keyframes moving
      0%
        transform: scaleY(500) translateY(5%)
      100%
        transform: scaleY(500) translateY(15%)
    img
      transform: scaleY(500)
      transition-duration: 1s
      animation: moving 40s infinite alternate

  *
    color: white
  .container
    max-width: 100%
  a
    color: white
    padding: 5px 10px
    &:hover
      text-decoration: none
  a h5
    color: white
    margin-top: 15px
    margin-bottom: 30px
    // border-bottom: 2px solid white

    font-weight: 600
    font-size: 1.6rem
    text-align: left
  img
    width: 100%
    height: auto
    box-shadow: 0px 20px 20px -10px rgba(black,0.1)
    border-radius: 5px
    overflow: hidden

  input
    width: 100%
    max-width: 300px
    border: solid 1px rgba(black,0.3)
    padding: 5px 20px
    border-radius: 500px
    background-color: transparent
    border: solid 2px white

  .icon
    filter: saturate(0)
    height: 25px
    width: auto
    margin-left: 50px
  .item
    // padding: 5px
    padding: 0
  .item-inner
    border: solid 1px rgba(255,255,255,0.3)
    padding: 3vmin
    transition-duration: 1s
    position: relative
    overflow: hidden
    .bg
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%,-50%)
      width: 100%
      opacity: 0
      z-index: 100
      pointer-events: none
      display: none
    &:hover
      border: solid 1px rgba(255,255,255,0.5)
      background-color: rgba(255,255,255,0.1)
      .barcode
        img
          transform: scaleY(500) translateY(1%)
      .bg
        opacity: 0.1
</style>
